var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-3" },
    [
      _vm.isLoading ? _c("LoadingCircle") : _vm._e(),
      !_vm.isLoading && _vm.isValidCertificate
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-5" }, [
              !this.isMobile
                ? _c(
                    "div",
                    { staticClass: "card", style: { height: "300px" } },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "card-titel" }, [
                          _c("h3", [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.certificateVerificationResponse
                                      .datafileTitle
                                  ) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("hr"),
                        _c("div", { staticClass: "card-text" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6 mb-3" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("datafileReport.uploadedBy"))
                                ),
                              ]),
                              _c("div", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      this.certificateVerificationResponse
                                        .companyName
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-6 mb-3" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.$t("datafileReport.createdFor"))
                                ),
                              ]),
                              this.certificateVerificationResponse.clientName
                                ? _c("div", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          this.certificateVerificationResponse
                                            .clientName
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              !this.certificateVerificationResponse.clientName
                                ? _c("div", [_c("strong", [_vm._v("-")])])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-md-6 mb-3" }, [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("datafileReport.creationDate")
                                  ) + ":"
                                ),
                              ]),
                              _c("div", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      new Date(
                                        this.certificateVerificationResponse.createdDate
                                      ).toLocaleDateString("de-DE")
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-md-6 mb-3" }, [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "datafileResultCleanedTab.reportWidget.certificateLevel"
                                      )
                                    ) +
                                    ": "
                                ),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      attrs: { variant: "gold" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.certificateVerificationResponse
                                              .certificateLevel
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "success" },
                                    on: { click: _vm.clickViewMoreButton },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "datafileResultCleanedTab.reportWidget.viewReportbutton"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              this.isMobile
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "card-titel" }, [
                        _c("h3", [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  this.certificateVerificationResponse
                                    .datafileTitle
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("hr"),
                      _c("div", { staticClass: "card-text" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6 mb-3" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("datafileReport.uploadedBy"))
                              ),
                            ]),
                            _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    this.certificateVerificationResponse
                                      .companyName
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6 mb-3" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("datafileReport.createdFor"))
                              ),
                            ]),
                            this.certificateVerificationResponse.clientName
                              ? _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        this.certificateVerificationResponse
                                          .clientName
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            !this.certificateVerificationResponse.clientName
                              ? _c("div", [_c("strong", [_vm._v("-")])])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "col-md-6 mb-3" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$t("datafileReport.creationDate"))
                              ),
                            ]),
                            _c("div", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    new Date(
                                      this.certificateVerificationResponse.createdDate
                                    ).toLocaleDateString("de-DE")
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-6 mb-3" }, [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "datafileResultCleanedTab.metadataWidget.certificateLevel"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "b-badge",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { variant: "gold" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          this.certificateVerificationResponse
                                            .certificateLevel
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "success" },
                                  on: { click: _vm.clickViewMoreButton },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "datafileResultCleanedTab.reportWidget.viewReport"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-lg-3" }, [
              !this.isMobile
                ? _c(
                    "div",
                    { staticClass: "card", style: { height: "300px" } },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _vm._m(0),
                          _c(
                            "div",
                            { staticClass: "col-12 mt-3 text-center" },
                            [
                              _c("h3", [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        this.certificateVerificationResponse
                                          .certificateCode
                                      )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              this.isMobile
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "col-12 mt-3 text-center" }, [
                          _c("h3", [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.certificateVerificationResponse
                                      .certificateCode
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-md-12 mt-4 text-center" }, [
              _c("p", [
                _vm._v("© " + _vm._s(new Date().getFullYear()) + " Redem GmbH"),
              ]),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "span",
                  {
                    staticClass: "mr-2",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.setLanguage(_vm.languages[0].language)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-1",
                      attrs: { src: _vm.languages[0].flag, height: "10" },
                    }),
                    _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
                  ]
                ),
                _vm._v(" • "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.setLanguage(_vm.languages[1].language)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "mr-1",
                      attrs: { src: _vm.languages[1].flag, height: "10" },
                    }),
                    _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.isLoading && !_vm.isValidCertificate
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-6 text-center" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("img", {
                    staticClass: "img-fluid mb-4",
                    staticStyle: { width: "60px" },
                    attrs: {
                      src: require("@/assets/images/red-warning.png"),
                      alt: "",
                    },
                  }),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "datafileReport.certificateVerification.notFoundMsg"
                        )
                      ) +
                      " "
                  ),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "light" },
                            on: {
                              click: function ($event) {
                                return _vm.clickBack()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "datafileReport.certificateVerification.backButton"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-12 mt-4 text-center" }, [
                _c("p", [
                  _vm._v(
                    "© " + _vm._s(new Date().getFullYear()) + " Redem GmbH"
                  ),
                ]),
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setLanguage(_vm.languages[0].language)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: _vm.languages[0].flag, height: "10" },
                      }),
                      _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
                    ]
                  ),
                  _vm._v(" • "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-2",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.setLanguage(_vm.languages[1].language)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: _vm.languages[1].flag, height: "10" },
                      }),
                      _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center" }, [
      _c("img", {
        staticClass: "img-fluid",
        staticStyle: { width: "200px" },
        attrs: {
          src: require("@/assets/images/redem-certification.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center" }, [
      _c("img", {
        staticClass: "img-fluid",
        staticStyle: { width: "200px" },
        attrs: {
          src: require("@/assets/images/redem-certification.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }